import React from "react";
import { Link, graphql } from "gatsby";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import Layout from "../../components/Layout";
import Slider from "react-slick";
import postFeedback from "../../components/PostFeedback";
import SEO from "../../components/SEO";

class Etask extends React.Component {
    constructor(props) {
        super(props);
        this.handleBiggerSmallerImg1 = this.handleBiggerSmallerImg1.bind(this);
        this.handleBiggerSmallerImg2 = this.handleBiggerSmallerImg2.bind(this);
        this.handleBiggerSmallerImg3 = this.handleBiggerSmallerImg3.bind(this);
        this.increaseImageWidthNativeApp = this.increaseImageWidthNativeApp.bind(this);
        this.increaseImageWidthLiveIntegration = this.increaseImageWidthLiveIntegration.bind(this);
        this.increaseImageWidthAutomationProcess = this.increaseImageWidthAutomationProcess.bind(this);
        this.increaseImageWidthTalkYourSide = this.increaseImageWidthTalkYourSide.bind(this);
        this.hitButtonTryItNow = this.hitButtonTryItNow.bind(this);
        this.hitButtonLiveDemoeTask = this.hitButtonLiveDemoeTask.bind(this);
        this.hitButtonPricingeTask = this.hitButtonPricingeTask.bind(this);
    }

    // componentDidMount() {
    //     const { location } = this.props;

    //     var host_website = location.host ? location.host : "";
    //     var new_online_doc_url;

    //     if (host_website === "localhost:8000" || host_website === "127.0.0.1:8000") {
    //         new_online_doc_url = "http://protoweb.appvity.com:8083/";
    //     } else if (host_website === "protoweb.appvity.com:8082") {
    //         new_online_doc_url = "http://protoweb.appvity.com:8083/";
    //     } else if (host_website === "vmweb:8082") {
    //         new_online_doc_url = "http://vmweb:8083/";
    //     } else if (host_website === "10.8.30.46:8082") {
    //         new_online_doc_url = "http://10.8.30.46:8083/";
    //     } else if (host_website === "10.9.9.104:8082") {
    //         new_online_doc_url = "http://10.9.9.104:8083/";
    //     } else {
    //         new_online_doc_url = "https://www.appvity.com:8083/";
    //     }

    //     document.querySelector(".app-section-etask-try-product-free .app-etask-try-product-free-detail .app-etask-try-product-free-faqs-onlinedoc a.app-etask-try-product-free-faqs-onlinedoc-a-tag").setAttribute("href", new_online_doc_url + "odoc/eTask-User-Guide/Introduction/01-00-introduction.html");
    // }

    handleBiggerSmallerImg1() {
        document.querySelector('.app-productdetail-rollup-tasks-detail-img img.app-product-detail-rollup-tasks-img-1').classList.add('active-1');
        document.querySelector('.app-productdetail-rollup-tasks-detail-img img.app-product-detail-rollup-tasks-img-2').classList.remove('active-2');
        document.querySelector('.app-productdetail-rollup-tasks-detail-img img.app-product-detail-rollup-tasks-img-3').classList.remove('active-3');

        document.querySelector('.app-productdetail-rollup-tasks-detail-img img.app-product-detail-rollup-tasks-img-1').classList.remove('not-active-1');
        document.querySelector('.app-productdetail-rollup-tasks-detail-img img.app-product-detail-rollup-tasks-img-2').classList.add('not-active-2-kind-1');
        document.querySelector('.app-productdetail-rollup-tasks-detail-img img.app-product-detail-rollup-tasks-img-2').classList.remove('not-active-2-kind-2');
        document.querySelector('.app-productdetail-rollup-tasks-detail-img img.app-product-detail-rollup-tasks-img-3').classList.add('not-active-3');
	}

    handleBiggerSmallerImg2() {
        document.querySelector('.app-productdetail-rollup-tasks-detail-img img.app-product-detail-rollup-tasks-img-1').classList.remove('active-1');
        document.querySelector('.app-productdetail-rollup-tasks-detail-img img.app-product-detail-rollup-tasks-img-2').classList.add('active-2');
        document.querySelector('.app-productdetail-rollup-tasks-detail-img img.app-product-detail-rollup-tasks-img-3').classList.remove('active-3');

        document.querySelector('.app-productdetail-rollup-tasks-detail-img img.app-product-detail-rollup-tasks-img-1').classList.add('not-active-1');
        document.querySelector('.app-productdetail-rollup-tasks-detail-img img.app-product-detail-rollup-tasks-img-2').classList.remove('not-active-2-kind-1');
        document.querySelector('.app-productdetail-rollup-tasks-detail-img img.app-product-detail-rollup-tasks-img-2').classList.remove('not-active-2-kind-2');
        document.querySelector('.app-productdetail-rollup-tasks-detail-img img.app-product-detail-rollup-tasks-img-3').classList.add('not-active-3');
	}

    handleBiggerSmallerImg3() {
        document.querySelector('.app-productdetail-rollup-tasks-detail-img img.app-product-detail-rollup-tasks-img-1').classList.remove('active-1');
        document.querySelector('.app-productdetail-rollup-tasks-detail-img img.app-product-detail-rollup-tasks-img-2').classList.remove('active-2');
        document.querySelector('.app-productdetail-rollup-tasks-detail-img img.app-product-detail-rollup-tasks-img-3').classList.add('active-3');

        document.querySelector('.app-productdetail-rollup-tasks-detail-img img.app-product-detail-rollup-tasks-img-1').classList.add('not-active-1');
        document.querySelector('.app-productdetail-rollup-tasks-detail-img img.app-product-detail-rollup-tasks-img-2').classList.add('not-active-2-kind-2');
        document.querySelector('.app-productdetail-rollup-tasks-detail-img img.app-product-detail-rollup-tasks-img-2').classList.remove('not-active-2-kind-1');
        document.querySelector('.app-productdetail-rollup-tasks-detail-img img.app-product-detail-rollup-tasks-img-3').classList.remove('not-active-3');
	}

    increaseImageWidthNativeApp() {
        document.querySelector('.app-section-productdetail-features .native-app .native-app-left').classList.toggle('native-app-left-active');
        document.querySelector('.app-section-productdetail-features .native-app .native-app-right').classList.toggle('native-app-right-active');

        document.querySelector('.app-section-productdetail-features .live-integration .live-integration-left').classList.remove('live-integration-left-active');
        document.querySelector('.app-section-productdetail-features .live-integration .live-integration-right').classList.remove('live-integration-right-active');
        document.querySelector('.app-section-productdetail-features .automation-process .automation-process-left').classList.remove('automation-process-left-active');
        document.querySelector('.app-section-productdetail-features .automation-process .automation-process-right').classList.remove('automation-process-right-active');
        document.querySelector('.app-section-productdetail-features .talk-your-side .talk-your-side-left').classList.remove('talk-your-side-left-active');
        document.querySelector('.app-section-productdetail-features .talk-your-side .talk-your-side-right').classList.remove('talk-your-side-right-active');
    }

    increaseImageWidthLiveIntegration() {
        document.querySelector('.app-section-productdetail-features .live-integration .live-integration-left').classList.toggle('live-integration-left-active');
        document.querySelector('.app-section-productdetail-features .live-integration .live-integration-right').classList.toggle('live-integration-right-active');

        document.querySelector('.app-section-productdetail-features .native-app .native-app-left').classList.remove('native-app-left-active');
        document.querySelector('.app-section-productdetail-features .native-app .native-app-right').classList.remove('native-app-right-active');
        document.querySelector('.app-section-productdetail-features .automation-process .automation-process-left').classList.remove('automation-process-left-active');
        document.querySelector('.app-section-productdetail-features .automation-process .automation-process-right').classList.remove('automation-process-right-active');
        document.querySelector('.app-section-productdetail-features .talk-your-side .talk-your-side-left').classList.remove('talk-your-side-left-active');
        document.querySelector('.app-section-productdetail-features .talk-your-side .talk-your-side-right').classList.remove('talk-your-side-right-active');
    }

    increaseImageWidthAutomationProcess() {
        document.querySelector('.app-section-productdetail-features .automation-process .automation-process-left').classList.toggle('automation-process-left-active');
        document.querySelector('.app-section-productdetail-features .automation-process .automation-process-right').classList.toggle('automation-process-right-active');

        document.querySelector('.app-section-productdetail-features .native-app .native-app-left').classList.remove('native-app-left-active');
        document.querySelector('.app-section-productdetail-features .native-app .native-app-right').classList.remove('native-app-right-active');
        document.querySelector('.app-section-productdetail-features .live-integration .live-integration-left').classList.remove('live-integration-left-active');
        document.querySelector('.app-section-productdetail-features .live-integration .live-integration-right').classList.remove('live-integration-right-active');
        document.querySelector('.app-section-productdetail-features .talk-your-side .talk-your-side-left').classList.remove('talk-your-side-left-active');
        document.querySelector('.app-section-productdetail-features .talk-your-side .talk-your-side-right').classList.remove('talk-your-side-right-active');
    }

    increaseImageWidthTalkYourSide() {
        document.querySelector('.app-section-productdetail-features .talk-your-side .talk-your-side-left').classList.toggle('talk-your-side-left-active');
        document.querySelector('.app-section-productdetail-features .talk-your-side .talk-your-side-right').classList.toggle('talk-your-side-right-active');

        document.querySelector('.app-section-productdetail-features .native-app .native-app-left').classList.remove('native-app-left-active');
        document.querySelector('.app-section-productdetail-features .native-app .native-app-right').classList.remove('native-app-right-active');
        document.querySelector('.app-section-productdetail-features .live-integration .live-integration-left').classList.remove('live-integration-left-active');
        document.querySelector('.app-section-productdetail-features .live-integration .live-integration-right').classList.remove('live-integration-right-active');
        document.querySelector('.app-section-productdetail-features .automation-process .automation-process-left').classList.remove('automation-process-left-active');
        document.querySelector('.app-section-productdetail-features .automation-process .automation-process-right').classList.remove('automation-process-right-active');
    }

    hitButtonTryItNow() {
        var data = {};
        data["type"] = "click";
        data["button"] = "tryItNow";
        
        var checkItemLocalStorage = localStorage.getItem("dataTryItNow");

        if (!checkItemLocalStorage) {
            postFeedback(`${process.env.feedbackAPI}`, data, 
            function(response) {
                console.log(response);
                localStorage.setItem("dataTryItNow", JSON.stringify(data));
            },
            function(error) {
                console.log("Something went wrong", error);
            });
        }
    };

    hitButtonLiveDemoeTask() {
        var data = {};
        data["type"] = "click";
        data["button"] = "liveDemoeTask";
        
        var checkItemLocalStorage = localStorage.getItem("dataLiveDemoeTask");

        if (!checkItemLocalStorage) {
            postFeedback(`${process.env.feedbackAPI}`, data, 
            function(response) {
                console.log(response);
                localStorage.setItem("dataLiveDemoeTask", JSON.stringify(data));
            },
            function(error) {
                console.log("Something went wrong", error);
            });
        }
    };

    hitButtonPricingeTask() {
        var data = {};
        data["type"] = "click";
        data["button"] = "pricingeTask";
        
        var checkItemLocalStorage = localStorage.getItem("dataPricingeTask");

        if (!checkItemLocalStorage) {
            postFeedback(`${process.env.feedbackAPI}`, data, 
            function(response) {
                console.log(response);
                localStorage.setItem("dataPricingeTask", JSON.stringify(data));
            },
            function(error) {
                console.log("Something went wrong", error);
            });
        }
    };

    render() {
        const { data } = this.props;
        const introduction = data.allMarkdownRemark.nodes;
        const native_app = data.native_app.nodes;
        const live_integration = data.live_integration.nodes;
        const automation_process = data.automation_process.nodes;
        const talking_work_side = data.talking_work_side.nodes;
        const explore_etask_capabilities_plan = data.explore_etask_capabilities_plan.nodes;
        const explore_etask_capabilities_manage = data.explore_etask_capabilities_manage.nodes;
        const explore_etask_capabilities_deliver = data.explore_etask_capabilities_deliver.nodes;
        const explore_etask_capabilities_report = data.explore_etask_capabilities_report.nodes;
        const features_automate_process = data.features_automate_process.nodes;
        const features_save_time_eBot = data.features_save_time_eBot.nodes;
        const extend_fit_business = data.extend_fit_business.nodes;
        const rollup_tasks_mul_project = data.rollup_tasks_mul_project.nodes;
        const etask_admin_portal = data.etask_admin_portal.nodes;
        const specifications_msteam_app = data.specifications_msteam_app.nodes;
        const specifications_mobile_app = data.specifications_mobile_app.nodes;
        const etask_try_product_free_live_demo = data.etask_try_product_free_live_demo.nodes;
        const etask_try_product_free_faqs_onlinedoc = data.etask_try_product_free_faqs_onlinedoc.nodes;
        const etask_explore_now = data.etask_explore_now.nodes;

        var customSettingsPraticalFeature = {
            dots: false,
            speed: 500,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 1367,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return(
            <Layout>
                <SEO title="eTask" description="Cloud based task management tool that works on Microsoft Teams, allows your team to keep track of your project with simple effective ways to create, assign, and reports for project. " meta={[{ name: `keywords`, content: ["Microsoft Teams productivity app","integrate with Teams", "collaboration", "task management"] }]} pathname="/products/etask" />
                <div className="main-content-padding">
                    <div className="app-general-seciton app-productdetail-introduction" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <div className="app-productdetail-introduction-detail">
                                <Row className="app-productdetail-introduction-row-custom">
                                    <Col className="app-productdetail-introduction-col-custom" xs={12} md={12} lg={5}>
                                        <div className="app-productdetail-introduction-left">
                                            {introduction.map((res) => (
                                                <div dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                            ))}
                                            {/* <h6><Link to="/products/demosite" onClick={this.hitButtonTryItNow}>Try It Now !</Link></h6> */}
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={1}></Col>
                                    <Col className="app-productdetail-introduction-col-custom" xs={12} md={12} lg={6}>
                                        <div className="app-productdetail-introduction-right">
                                            <img className="app-productdetail-introduction-img" src="/app-product-detail-introduction.png" alt="product detail introduction" />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                    <div className="app-general-seciton app-section-productdetail-features" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={2}></Col>
                                <Col xs={12} md={12} lg={8}>
                                    <div className="app-title">
                                        <h2>The most effective collaboration tool helps <br/> your enterprise enhance productivity</h2>
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={2}></Col>
                            </Row>
                            <div className="app-productdetail-features-detail">
                                <Row className="app-productdetail-features-detail-row-custom native-app">
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                    <Col className="app-productdetail-features-detail-col-custom native-app-left" xs={12} md={12} lg={12} xl={5}>
                                        {native_app.map((res) => (
                                            <div className="app-features-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                        ))}
                                    </Col>
                                    <Col className="app-productdetail-features-detail-col-custom native-app-right" xs={12} md={12} lg={12} xl={5}>
                                        <div className="app-features-right-side">
                                            <img 
                                                className="app-product-detail-features-native-app" 
                                                src="/app-product-detail-features-native-app.svg" 
                                                alt="app product detail features native app" 
                                                onClick={this.increaseImageWidthNativeApp} 
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                </Row>
                                <Row className="app-productdetail-features-detail-row-custom live-integration">
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                    <Col className="app-productdetail-features-detail-col-custom live-integration-left" xs={12} md={12} lg={12} xl={5}>
                                        <div className="app-features-left-side">
                                            <img 
                                                className="app-product-detail-features-live-integration" 
                                                src="/app-product-detail-features-live-integration.svg" 
                                                alt="app product detail features live integration" 
                                                onClick={this.increaseImageWidthLiveIntegration} 
                                            />
                                        </div>
                                    </Col>
                                    <Col className="app-productdetail-features-detail-col-custom live-integration-right" xs={12} md={12} lg={12} xl={5}>
                                        {live_integration.map((res) => (
                                            <div className="app-features-right-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                        ))}
                                    </Col>
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                </Row>
                                <Row className="app-productdetail-features-detail-row-custom automation-process">
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                    <Col className="app-productdetail-features-detail-col-custom automation-process-left" xs={12} md={12} lg={12} xl={5}>
                                        {automation_process.map((res) => (
                                            <div className="app-features-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                        ))}
                                    </Col>
                                    <Col className="app-productdetail-features-detail-col-custom automation-process-right" xs={12} md={12} lg={12} xl={5}>
                                        <div className="app-features-right-side">
                                            <img 
                                                className="app-product-detail-features-automation-process" 
                                                src="/app-product-detail-features-automation-process.svg" 
                                                alt="app product detail features automation process" 
                                                onClick={this.increaseImageWidthAutomationProcess} 
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                </Row>
                                <Row className="app-productdetail-features-detail-row-custom talk-your-side">
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                    <Col className="app-productdetail-features-detail-col-custom talk-your-side-left" xs={12} md={12} lg={12} xl={5}>
                                        <div className="app-features-left-side">
                                            <img 
                                                className="app-product-detail-features-talking-work-side" 
                                                src="/app-product-detail-features-talking-work-side.svg" 
                                                alt="app product detail features talking work side" 
                                                onClick={this.increaseImageWidthTalkYourSide} 
                                            />
                                        </div>
                                    </Col>
                                    <Col className="app-productdetail-features-detail-col-custom talk-your-side-right app-img-section-custom" xs={12} md={12} lg={12} xl={5}>
                                        {talking_work_side.map((res) => (
                                            <div className="app-features-right-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                        ))}
                                    </Col>
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                    <div className="app-general-seciton app-section-productdetail-features-explore-capabilities" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="linear">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={2}></Col>
                                <Col xs={12} md={12} lg={8}>
                                    <div className="app-title">
                                        <h2>Practical features of eTask capabilities</h2>
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={2}></Col>
                            </Row>
                            <div className="app-productdetail-features-explore-capabilities-detail">
                                <div className="app-productdetail-features-tabs">
                                    <Tabs defaultActiveKey="Plan">
                                        <Tab eventKey="Plan" title="Plan">
                                            <Slider {...customSettingsPraticalFeature}>
                                                {explore_etask_capabilities_plan.map((res) => (
                                                    <div className="app-features-slider" key={res.id}>
                                                        <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                                        <div className="content">
                                                            <h5>{res.frontmatter.title}</h5>
                                                            <h6>{res.frontmatter.description}</h6>
                                                        </div>
                                                    </div>
                                                ))}
                                            </Slider>
                                        </Tab>
                                        <Tab eventKey="Manage" title="Manage">
                                            <Slider {...customSettingsPraticalFeature}>
                                                {explore_etask_capabilities_manage.map((res) => (
                                                    <div className="app-features-slider" key={res.id}>
                                                        <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                                        <div className="content">
                                                            <h5>{res.frontmatter.title}</h5>
                                                            <h6>{res.frontmatter.description}</h6>
                                                        </div>
                                                    </div>
                                                ))}
                                            </Slider>
                                        </Tab>
                                        <Tab eventKey="Deliver" title="Deliver">
                                            <Slider {...customSettingsPraticalFeature}>
                                                {explore_etask_capabilities_deliver.map((res) => (
                                                    <div className="app-features-slider" key={res.id}>
                                                        <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                                        <div className="content">
                                                            <h5>{res.frontmatter.title}</h5>
                                                            <h6>{res.frontmatter.description}</h6>
                                                        </div>
                                                    </div>
                                                ))}
                                            </Slider>
                                        </Tab>
                                        <Tab eventKey="Report" title="Report">
                                            <Slider {...customSettingsPraticalFeature}>
                                                {explore_etask_capabilities_report.map((res) => (
                                                    <div className="app-features-slider" key={res.id}>
                                                        <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                                        <div className="content">
                                                            <h5>{res.frontmatter.title}</h5>
                                                            <h6>{res.frontmatter.description}</h6>
                                                        </div>
                                                    </div>
                                                ))}
                                            </Slider>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </Container>
                    </div>
                    <div className="app-general-seciton app-section-productdetail-automate-eBot" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="linear">
                        <Container>
                            <div className="app-productdetail-automate-eBot-detail">
                                <Row>
                                    {features_automate_process.map((res) => (
                                        <Col className="app-productdetail-automate-eBot-detail-col-custom" xs={12} md={12} lg={6} key={res.id}>
                                            <div className="app-productdetail-automate-detail">
                                                <h2>{res.frontmatter.title}</h2>
                                                <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                            </div>
                                        </Col>
                                    ))}
                                    {features_save_time_eBot.map((res) => (
                                        <Col className="app-productdetail-automate-eBot-detail-col-custom" xs={12} md={12} lg={6} key={res.id}>
                                            <div className="app-productdetail-eBot-detail">
                                                <h2>{res.frontmatter.title}</h2>
                                                <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </Container>
                    </div>
                    <div className="app-general-seciton app-section-productdetail-extend-eTask" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="linear">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={2}></Col>
                                <Col xs={12} md={12} lg={8}>
                                    <div className="app-title">
                                        <h2>Extend eTask to fit in your business</h2>
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={2}></Col>
                            </Row>
                            <div className="app-productdetail-extend-eTask-detail">
                                <Row>
                                    {extend_fit_business.map((res) => (
                                        <Col xs={12} md={12} lg={6} key={res.id}>
                                            <div className="app-productdetail-extend-eTask-detail-content">
                                                <h4>{res.frontmatter.title}</h4>
                                                <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </Container>
                    </div>
                    <div className="app-general-seciton app-section-productdetail-rollup-tasks" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="linear">
                        <Container>
                            <div className="app-productdetail-rollup-tasks-detail">
                                <Row>
                                    <Col xs={12} md={12} lg={2}></Col>
                                    <Col xs={12} md={12} lg={8}>
                                        {rollup_tasks_mul_project.map((res) => (
                                            <div className="app-productdetail-rollup-tasks-detail-content" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                    </Col>
                                    <Col xs={12} md={12} lg={2}></Col>
                                </Row>
                                <div className="app-productdetail-rollup-tasks-detail-list-img">
                                    <div className="app-productdetail-rollup-tasks-detail-img">
                                        <img className="app-product-detail-rollup-tasks-img-1 not-active-1" 
                                            src="/app-product-detail-rollup-tasks-img-1.svg" 
                                            alt="app product detail rollup tasks img 1" 
                                            onClick={this.handleBiggerSmallerImg1} 
                                            onKeyDown={this.handleBiggerSmallerImg1}
                                        />
                                        <img className="app-product-detail-rollup-tasks-img-1-mobile-tablet" 
                                            src="/app-product-detail-rollup-tasks-img-1.svg" 
                                            alt="app product detail rollup tasks img 1 mobile tablet" 
                                        />
                                        <h6>My Tasks</h6>
                                    </div>
                                    <div className="app-productdetail-rollup-tasks-detail-img">
                                        <img className="app-product-detail-rollup-tasks-img-2 active-2" 
                                            src="/app-product-detail-rollup-tasks-img-2.svg" 
                                            alt="app product detail rollup tasks img 2" 
                                            onClick={this.handleBiggerSmallerImg2} 
                                            onKeyDown={this.handleBiggerSmallerImg2}
                                        />
                                        <img className="app-product-detail-rollup-tasks-img-2-mobile-tablet" 
                                            src="/app-product-detail-rollup-tasks-img-2.svg" 
                                            alt="app product detail rollup tasks img 2 mobile tablet" 
                                        />
                                        <h6>My Projects</h6>
                                    </div>
                                    <div className="app-productdetail-rollup-tasks-detail-img">
                                        <img className="app-product-detail-rollup-tasks-img-3 not-active-3" 
                                            src="/app-product-detail-rollup-tasks-img-3.svg" 
                                            alt="app product detail rollup tasks img 3" 
                                            onClick={this.handleBiggerSmallerImg3} 
                                            onKeyDown={this.handleBiggerSmallerImg3}
                                        />
                                        <img className="app-product-detail-rollup-tasks-img-3-mobile-tablet" 
                                            src="/app-product-detail-rollup-tasks-img-3.svg" 
                                            alt="app product detail rollup tasks img 3 mobile tablet" 
                                        />
                                        <h6>PM Dashboard</h6>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                    <div className="app-general-seciton app-section-for-mobile" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="linear">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={2}></Col>
                                <Col xs={12} md={12} lg={8}>
                                    <div className="app-title">
                                        <h2>Connect from any device, anywhere</h2>
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={2}></Col>
                            </Row>
                            <div className="app-for-mobile-detail">
                                <Row>
                                    <Col className="app-for-mobile-detail-col-custom" xs={12} md={12} lg={12} xl={6}>
                                        <div className="app-for-mobile-eTask-title">
                                            <h4>On eTask App</h4>
                                        </div>
                                        <div className="app-for-mobile-eTask-content">
                                            <Row>
                                                <Col xs={6} md={6} lg={6} className="app-row-for-mobile-operating-system">
                                                    <img src="/app-etask-android.png" alt="app etask android"/>
                                                    {/* <Link to="/" className="app-for-mobile-operating-system-link">
                                                        <img src="/app-icon-download.svg" alt="app icon download"/>Android
                                                    </Link> */}
                                                    <h5 className="app-for-mobile-operating-system">Android</h5>
                                                </Col>
                                                <Col xs={6} md={6} lg={6} className="app-row-for-mobile-operating-system">
                                                    <img src="/app-etask-ios.png" alt="app etask ios"/>
                                                    {/* <Link to="/" className="app-for-mobile-operating-system-link">
                                                        <img src="/app-icon-download.svg" alt="app icon download"/>iOS
                                                    </Link> */}
                                                    <h5 className="app-for-mobile-operating-system">iOS</h5>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col className="app-for-mobile-detail-col-custom" xs={12} md={12} lg={12} xl={6}>
                                        <div className="app-for-mobile-MSTeam-title">
                                            <h4>On Microsoft Teams</h4>
                                        </div>
                                        <div className="app-for-mobile-MSTeam-content">
                                            <Row>
                                                <Col xs={6} md={6} lg={6}>
                                                    <img src="/app-msteams-android.png" alt="app msteams android"/>
                                                    <h5 className="app-for-mobile-operating-system">Android</h5>
                                                </Col>
                                                <Col xs={6} md={6} lg={6}>
                                                    <img src="/app-msteams-ios.png" alt="app msteams ios"/>
                                                    <h5 className="app-for-mobile-operating-system">iOS</h5>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                    <div className="app-general-seciton app-etask-admin-portal" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="linear">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={2}></Col>
                                <Col xs={12} md={12} lg={8}>
                                    {etask_admin_portal.map((res) => (
                                        <div className="app-etask-admin-portal-detail" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                    ))}
                                </Col>
                                <Col xs={12} md={12} lg={2}></Col>
                            </Row>
                        </Container>
                    </div>
                    {/* <div className="app-general-seciton app-section-productdetail-specifications" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="linear">
                        <Container>
                            <div className="app-title">
                                <h2>Specifications</h2>
                            </div>
                            <div className="app-productdetail-specifications-detail">
                                <Row>
                                    <Col className="app-productdetail-specifications-detail-col-custom" xs={12} md={6} lg={6}>
                                        <Row>
                                            <Col>
                                                <div className="app-productdetail-specifications-detail-header">
                                                    <h6>System Specifications for eTask Teams app</h6>
                                                </div>
                                            </Col>
                                        </Row>
                                        {specifications_msteam_app.map((res) => (
                                            <Row className="app-productdetail-specifications-detail-row-custom" key={res.id}>
                                                <Col className="app-productdetail-specifications-detail-col-custom-child" xs={4} md={4} lg={2}>
                                                    <h6>{res.frontmatter.title}</h6>
                                                </Col>
                                                <Col className="app-productdetail-specifications-detail-col-custom-child" xs={8} md={8} lg={10}>
                                                    <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                                </Col>
                                            </Row>
                                        ))}
                                    </Col>
                                    <Col className="app-productdetail-specifications-detail-col-custom" xs={12} md={6} lg={6}>
                                        <Row>
                                            <Col>
                                                <div className="app-productdetail-specifications-detail-header">
                                                    <h6>System Specifications for eTask Mobile app</h6>
                                                </div>
                                            </Col>
                                        </Row>
                                        {specifications_mobile_app.map((res) => (
                                            <Row className="app-productdetail-specifications-detail-row-custom" key={res.id}>
                                                <Col className="app-productdetail-specifications-detail-col-custom-child" xs={4} md={4} lg={2}>
                                                    <h6>{res.frontmatter.title}</h6>
                                                </Col>
                                                <Col className="app-productdetail-specifications-detail-col-custom-child" xs={8} md={8} lg={10}>
                                                    <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                                </Col>
                                            </Row>
                                        ))}
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div> */}
                    <div className="app-general-seciton app-section-etask-try-product-free" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="linear">
                        <Container>
                            <div className="app-etask-try-product-free-detail">
                                <Row>
                                    {/* {etask_try_product_free_live_demo.map((res) => (
                                        <Col xs={12} md={12} lg={4} key={res.id}>
                                            <div className="app-etask-try-product-free-live-demo">
                                                <h4>{res.frontmatter.title}</h4>
                                                <h6><Link to="/products/demosite" onClick={this.hitButtonLiveDemoeTask}>Try It Now !</Link></h6>
                                            </div>
                                        </Col>
                                    ))} */}
                                    {etask_try_product_free_faqs_onlinedoc.map((res) => (
                                        <Col xs={12} md={12} lg={12} key={res.id}>
                                        {/* <Col xs={12} md={12} lg={4} key={res.id}> */}
                                            <div className="app-etask-try-product-free-faqs-onlinedoc">
                                                <h4>{res.frontmatter.title}</h4>
                                                <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </Container>
                    </div>
                    {/* <div className="app-section-etask-explore-now" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="linear">
                        {etask_explore_now.map((res) => (
                            <div key={res.id}>
                                <div className="app-title">
                                    <h4>{res.frontmatter.title}</h4>
                                </div>
                                <div className="app-section-etask-explore-now-detail">
                                    <h6><Link to="/pricing" onClick={this.hitButtonPricingeTask}>Subscribe</Link></h6>
                                </div>
                            </div>
                        ))}
                    </div> */}
                </div>
            </Layout>
        )
    }
}

export default Etask;

export const query = graphql`
    query eTaskPage {
        allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "etask_introduction" } } }
        ) {
            nodes {
                html
                id
            }
        }
        native_app: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "etask_native_app" } } }
        ) {
            nodes {
                html
                id
            }
        }
        live_integration: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "etask_live_integration" } } }
        ) {
            nodes {
                html
                id
            }
        }
        automation_process: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "etask_automation_process" } } }
        ) {
            nodes {
                html
                id
            }
        }
        talking_work_side: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "etask_talking_work_side" } } }
        ) {
            nodes {
                html
                id
            }
        }
        explore_etask_capabilities_plan: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "etask_explore_etask_capabilities_plan" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                    description
                }
                id
            }
        }
        explore_etask_capabilities_manage: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "etask_explore_etask_capabilities_manage" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                    description
                }
                id
            }
        }
        explore_etask_capabilities_deliver: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "etask_explore_etask_capabilities_deliver" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                    description
                }
                id
            }
        }
        explore_etask_capabilities_report: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "etask_explore_etask_capabilities_report" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                    description
                }
                id
            }
        }
        features_automate_process: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "etask_features_automate_process" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
                id
            }
        }
        features_save_time_eBot: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "etask_features_save_time_eBot" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
                id
            }
        }
        extend_fit_business: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "etask_extend_fit_business" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
                id
            }
        }
        rollup_tasks_mul_project: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "etask_rollup_tasks_mul_project" } } }
        ) {
            nodes {
                html
                id
            }
        }
        etask_admin_portal: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "etask_admin_portal" } } }
        ) {
            nodes {
                html
                id
            }
        }
        specifications_msteam_app: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "etask_specifications_msteam_app" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
                id
            }
        }
        specifications_mobile_app: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "etask_specifications_mobile_app" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
                id
            }
        }
        etask_try_product_free_live_demo: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "etask_try_product_free_live_demo" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
                id
            }
        }
        etask_try_product_free_faqs_onlinedoc: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "etask_try_product_free_faqs_onlinedoc" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
                id
            }
        }
        etask_explore_now: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "etask_explore_now" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
                id
            }
        }
    }
`;

